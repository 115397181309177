.web-cam {
  /* width: 100%; */
  position: relative;
  margin-top: 100px;
  padding: 10px;
}

.web-cam .cam-buttons {
  position: absolute;
  bottom: 30px;
  left: 45%;
}

.web-cam .cam-buttons .cam-start,
.web-cam .cam-buttons .cam-stop {
  background-color: #ff2525;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.web-cam .cam-buttons .cam-stop {
  border-radius: 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.web-cam .cam-buttons .cam-stop .stop-icon {
  width: 12px;
  height: 12px;
  background-color: #ff2525;
}

.web-cam .cam-stream-time {
  position: absolute;
  top: 20px;
  left: 39%;
  color: #fff;
  padding: 3px 10px;
  font-weight: 600;
  border-radius: 3px;
}

.cam-download {
  border: none;
  background-color: #106EAC;
  padding: 10px 25px;
  color: white;
  border-radius: 16px;
  font-weight: 600;
}

/*# sourceMappingURL=style.css.map */

.recorded-video-style {
  width: 100%
}

@media (max-width:1000px) {

  .web-cam .cam-stream-time {
    top: 20px;
    left: 40%;
    font-size: 12px;
  }
  .web-cam .cam-buttons {
    left: 45%;
  }
}

@media (max-width:500px) {

  .web-cam .cam-stream-time {
    top: 20px;
    left: 40%;
    font-size: 10px;
  }
  .web-cam .cam-buttons {
    left: 45%;
  }
}